<template>
  <div class="hardware">
    <div class="hardware-left">
      <div class="title" @click="toItem('/replace?item=2')">
        <p>开发板</p>
        <div>
          <img src="@/assets/img/menu/menu1.png" alt="" />
        </div>
      </div>
      <div class="hardware-product-list">
        <div class="content" v-for="(el,index) in devData" :key="index" @click="toItem(el.url)">
          <div>
            <img :src="el.img" alt="图片">
          </div>
          <p>{{el.title}}</p>
        </div>
      </div>
    </div>
    <div class="hardware-right">
      <div class="title" @click="toItem('/replace?item=1')">
        <p>SOC</p>
        <div>
          <img src="@/assets/img/menu/menu1.png" alt="" />
        </div>
      </div>
      <div class="hardware-product-list">
        <div class="content" v-for="(el,index) in socData" :key="index" @click="toItem(el.url)">
          <div>
            <img :src="el.img" alt="图片">
          </div>
          <p>{{el.title}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VImg from "@/components/VImg";
export default {
  name: "hardware",
  components:{
    VImg
  },
  data(){
    return{
      devData:[{
        title:'Shenkaihong LYEVK-3861A',
        url:'/replace/productInfo/dev?id=1517038009399156738',
        img:require('@/assets/img/menu/hardware1.png')
      },{
        title:'Shenkaihong LYEVK-3861B',
        url:'/replace/productInfo/dev?id=1517039175033987074',
        img:require('@/assets/img/menu/hardware2.png')
      },{
        title:'KHDVK-3566B',
        url:'/replace/productInfo/dev?id=1518634156190404609',
        img:require('@/assets/img/menu/hardware3.png')
      },{
        title:'KHDVK-3588',
        url:'/replace/productInfo/dev?id=1518633081173512194',
        img:require('@/assets/img/menu/hardware4.png')
      }],
      socData:[{
        title:'LYB-S1B2 智能物联网模组',
        url:'/replace/productInfo/soc?id=1545309462745698306',
        img:require('@/assets/img/menu/hardware5.png')
      },{
        title:'LYB-S3B2 智能物联网模组',
        url:'/replace/productInfo/soc?id=1545310793397030914',
        img:require('@/assets/img/menu/hardware7.png')
      },{
        title:'LYW-S1H1 智能物联网模组',
        url:'/replace/productInfo/soc?id=1545317512462323713',
        img:require('@/assets/img/menu/hardware5.png')
      },{
        title:'LYB-P1B8 智能物联网模组',
        url:'/replace/productInfo/soc?id=1545316592148140034',
        img:require('@/assets/img/menu/hardware6.png')
      }]
    }
  },
  methods:{
    toItem(url){
      this.$emit('change',false)
      this.$router.push(url)
    }
  }
};
</script>

<style scoped lang="scss">
.hardware {
  position: relative;
  display: flex;
  height: 294px;
  .hardware-left{
    position: relative;
    border-right: 1px solid #EEEEEE;
  }
  .hardware-right{
    margin-left: 40px;
  }


  .title {
    display: flex;
    margin-bottom: 24px;
    cursor: pointer;
    > p {
      font-size: 14px;
      font-weight: bold;
      line-height: 22px;
      color: #ea0b06;
      transition:all 0.3s ease-in-out;
    }
    >p:hover{
      margin-right: 8px;
    }
    > div {
      width: 30px;
      height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .hardware-product-list{
    display: flex;
    flex-wrap: wrap;
  }
  .content {
    width: 50%;
    margin-bottom: 32px;
    cursor: pointer;
    > div {
      width: 80px;
      height: 80px;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      > img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    > p {
      font-size: 14px;
      line-height: 20px;
      color: #333333;
    }
    &:hover{
      >p{
        color: #EA0B06;
      }
    }
  }
  >div{
    width: 590px;
  }
  > .hardware-product {
   // margin-right: 40px;
  }
}
</style>
