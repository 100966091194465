<template>
  <a-upload
    name="file"
    :action="action"
    :headers="{ Authorization: `Bearer ${access_token}` }"
    :show-file-list="false"
    :show-upload-list="false"
    @change="handleChange"
  >
    <a-button> <a-icon type="upload" /> {{text}} </a-button>
  </a-upload>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props:{
    text:{
      type:String,
      default:'上传文件'
    },
    fileTypeCode: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters(['access_token']),
    action () {
      return `${process.env.VUE_APP_API_PORTAL_URL}/file/upload/${this.fileTypeCode}`
    }
  },
  data() {
    return {
      
    };
  },
  methods: {
    handleChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        // console.log(info.file.response.data)
        this.$emit('success', info.file.response.data)
      } else if (info.file.status === "error") {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },
  },
};
</script>