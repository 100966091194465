<template>
  <div class="home-footer">
    <div class="home-footer-box">
      <div class="home-footer-lable">
        <div>
          <div>
            <img src="@/assets/img/zxq.png" alt="图片"/>
            <p>找需求</p>
          </div>
        </div>
        <div>
          <div>
            <img src="@/assets/img/ccj.png" alt="图片"/>
            <p>促场景</p>
          </div>
        </div>
        <div>
          <div>
            <img src="@/assets/img/zsj.png" alt="图片"/>
            <p>助升级</p>
          </div>
        </div>
        <div>
          <div>
            <img src="@/assets/img/st.png" alt="图片"/>
            <p>构生态</p>
          </div>
        </div>
        <div>
          <div>
            <img src="@/assets/img/sf.png" alt="图片"/>
            <p>建示范</p>
          </div>
        </div>
      </div>
      <div class="home-footer-serve">
        <div>
          <div>
            <img src="@/assets/img/fwdh.png" alt="图片"/>
            <p>7*12小时</p>
          </div>
        </div>
        <div>
          <div>
            <img src="@/assets/img/fwvip.png" alt="图片"/>
            <p>VIP一对一</p>
          </div>
        </div>
        <div>
          <div>
            <img src="@/assets/img/fwzq.png" alt="图片"/>
            <p>全周期专业服务</p>
          </div>
        </div>
      </div>
      <div class="home-footer-link">
        <div class="home-footer-consult">
          <span>售前咨询热线</span>
          <div>
            <p><img src="@/assets/img/fodh.png" alt="图片"/> 400-025-6877</p>
            <p>
              <img src="@/assets/img/foyx.png" alt="图片"/>
              IIG.RDD.honglian@chinasofti.com
            </p>
            <div>
              <div>
                <p
                    @mouseenter="codeImgIndex = 1"
                    @mouseleave="codeImgIndex = 1"
                >
                  <img src="@/assets/img/qywx.png" alt="图片"/>企业微信
                </p>
                <p
                    @mouseenter="codeImgIndex = 2"
                    @mouseleave="codeImgIndex = 1"
                >
                  <img src="@/assets/img/fwh.png" alt="图片"/>服务号
                </p>
                <p
                    @mouseenter="codeImgIndex = 3"
                    @mouseleave="codeImgIndex = 1"
                >
                  <img src="@/assets/img/gzh.png" alt="图片"/>公众号
                </p>
              </div>
              <img
                  src="@/assets/img/wxm.png"
                  alt="二维码"
                  v-show="codeImgIndex == 1"
              />
              <img
                  src="@/assets/img/fwhm.png"
                  alt="二维码"
                  v-show="codeImgIndex == 2"
              />
              <img
                  src="@/assets/img/gzhm.jpeg"
                  alt="二维码"
                  v-show="codeImgIndex == 3"
              />
            </div>
          </div>
        </div>
        <div class="home-footer-link-box">
          <div>
            <span>关于鸿联云</span>
            <a-anchor :affix="false" wrapper-class="home-footer-link-anchor">
              <a-anchor-link href="#cloud" title="云平台"/>
              <a-anchor-link href="#centre" title="六中心"/>
              <a-anchor-link href="#service" title="全服务"/>
              <a-anchor-link href="#opportunity" title="准商机"/>
              <a-anchor-link href="#result" title="典型成果"/>
              <a-anchor-link href="#about" title="了解鸿联"/>
            </a-anchor>
          </div>
          <div>
            <span>支持与服务</span>
            <p @click="consultShowBtn">服务咨询</p>
            <p
                @click="toUrl('http://prod.gitok.com/userLayout/userScenes/add')"
            >
              国产化迁移咨询
            </p>
          </div>
          <div>
            <span>热门方案</span>
            <p
                @click="solutionBtn( '1500000000000000021')"
            >
              金融
            </p>
            <p
                @click="solutionBtn( '1500000000000000022')"
            >
              党政
            </p>
            <p
                @click="solutionBtn('1500000000000000011')"
            >
              消费
            </p>
            <p
                @click="solutionBtn('1500000000000000023')"
            >
              能源
            </p>
            <p
                @click="solutionBtn('1500000000000000034')"
            >
              工业
            </p>
            <p
                @click="solutionBtn('1500000000000000025')"
            >
              公检法
            </p>
            <p
                @click="solutionBtn( '1500000000000000026')"
            >
              交通
            </p>
          </div>
          <div>
            <span>开鸿知识云</span>
            <p @click="toHome('/replace?item=3')">IoT产品</p>
            <p @click="toHome('/replace?item=2')">开发板</p>
          </div>
          <div>
            <span>快速链接</span>
            <p @click="toUrl('https://www.chinasofti.com/')">中软国际</p>
            <p @click="toUrl('https://www.jfh.com/')">解放号</p>
            <p @click="toUrl('https://www.kaihong.com/')">深开鸿</p>
            <p @click="toUrl('https://www.openatom.org/#/')">
              开放原子开源基金会
            </p>
          </div>
        </div>
      </div>
      <div class="home-footer-copyright">
        <span
        >Copyright © 2013- Copyright © 北京掌中无限信息技术有限公司 All rights
          reserved</span
        >
        <span>工信部备案号：京ICP备09083730号</span>
      </div>
    </div>
    <div class="home-footer-frame">

      <!--      挥手摇头-->
      <img v-show="this.jqrshow.jqr_yt" src="@/assets/img/jqr_yt.png" alt="jqr" class="udesk"/>

      <!--      眨眼-->
      <img v-show="this.jqrshow.jqr_zy" src="@/assets/img/jqr_zy.png" alt="jqr" class="udesk"/>

      <!--      旋转-->
      <img v-show="this.jqrshow.jqr_xz" src="@/assets/img/jqr_xz.png" alt="jqr" class="udesk"/>

      <div @mouseenter="connectShowBtn(true)" @mouseleave="connectShowBtn(false)">
        <a-icon type="customer-service"/>
        <span>联系我们</span>
        <div
            class="home-floating-connect-box"
            v-if="connectShow"
            @mouseenter="connectShowBtn(true)"
            @mouseleave="connectShowBtn(false)"
        >
          <div>
            <div>
              <span>咨询热线</span>
              <strong style="color: #8C8C8C">400-025-6877</strong>
            </div>
          </div>

          <div>
            <div class="zxkf-title udesk" >
              <span id="zxkf">在线客服</span>
              <p id="zxkf-udesk">专业人员在线解答您的疑问</p>
            </div>
          </div>
          <div>
            <div>
              <span>咨询邮箱</span>
              <p style="color: #8C8C8C">IIG.RDD.honglian@chinasofti.com</p>
            </div>
          </div>
          <div>
            <div class="home-footer-frame-wx">
              <div>
                <p @mouseenter="codeImgIndex = 1" @mouseleave="codeImgIndex = 1">
                  <img src="@/assets/img/wx@2x.png" alt="图片"/>企业微信
                </p>
                <p @mouseenter="codeImgIndex = 2" @mouseleave="codeImgIndex = 1">
                  <img src="@/assets/img/fwh@2x.png" alt="图片"/>服务号
                </p>
                <p @mouseenter="codeImgIndex = 3" @mouseleave="codeImgIndex = 1">
                  <img src="@/assets/img/gzh@2x.png" alt="图片"/>公众号
                </p>
              </div>
              <img src="@/assets/img/wxm.png" alt="二维码" v-show="codeImgIndex == 1"/>
              <img src="@/assets/img/fwhm.png" alt="二维码" v-show="codeImgIndex == 2"/>
              <img src="@/assets/img/gzhm.jpeg" alt="二维码" v-show="codeImgIndex == 3"/>
            </div>
          </div>
        </div>
      </div>
      <div @click="consultShow = true">
        <span><img alt="图片" src="@/assets/img/wyzx.png"/></span>
        <span>我要咨询</span>
      </div>
      <div @click="toTop">
        <a-icon type="vertical-align-top"/>
        <span>回到顶部</span>
      </div>
    </div>
    <consult-form :show="consultShow" v-model="consultShow"/>


  </div>


</template>


<script>
let isShow = false
import VCountDown from "@/components/VCountDown";
import uploadFiles from "@/components/uploadFiles";
import {getConsultCreate} from "@/api/news";
import {sendMobile} from "@/api/common";

import consultForm from "@/components/consultForm";

export default {
  components: {
    VCountDown,
    uploadFiles,
    consultForm
  },
  data() {
    const validatorPhone = (rule, value, callback) => {
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("手机号格式不正确!"));
      } else {
        callback();
      }
    };
    return {
      showBtn: false,
      codeImgIndex: 1,
      connectShow: false,
      consultShow: false,
      isCode: true,
      categoryData: [],
      jqrshow: {
        jqr: false,
        jqr_yt: false,
        jqr_zy: true,
        jqr_xz: false,
      },
    };
  },
  mounted() {
    window.addEventListener("scroll", this.showbtn, true);
    this.jqrchange();
    setInterval(this.jqrchange, 41800);
  },
  methods: {

    //动画时间控制
    animation(obj, time) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          this.jqrshow = obj
          resolve()
        }, time)
      })
    },


    async jqrchange() {

      //眨眼
      let jqr_zybool = {
        jqr_yt: false,
        jqr_zy: true,
        jqr_xz: false,
      }

      //摇头
      let jqr_ytbool = {
        jqr_yt: true,
        jqr_zy: false,
        jqr_xz: false,
      }

      //旋转
      let jqr_xzbool = {
        jqr_yt: false,
        jqr_zy: false,
        jqr_xz: true,
      }

      //眨眼
      await this.animation(jqr_zybool, 0)

      //摇头
      await this.animation(jqr_ytbool, 6900)

      //眨眼
      await this.animation(jqr_zybool, 15200)

      //旋转
      await this.animation(jqr_xzbool, 6900)


    },


    connectShowBtn(is) {
      isShow = is
      if (!is) {
        setTimeout(() => {
          this.connectShow = isShow;
        }, 100);
      } else {
        this.connectShow = is;
      }
    },
    consultShowBtn() {
      this.consultShow = !this.consultShow;
      this.connectShow = false;
    },
    sendMobileCode() {
      const {contactPhone} = this.form;
      sendMobile(contactPhone).then(() => {
        this.$message.success("发送成功！");
      });
    },
    // 上传
    uploadChange(val) {
      this.form.annex = val.id;
    },
    onSubmit() {
      let {form} = this;
      let _this = this;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let data = {
            consultTitle: form.consultTitle,
            consultContent: form.consultDesc,
            contactDetail: form.contactPhone,
            contactPerson: form.contactName,
            consultAttmId: form.annex,
            contactCompany: "",
          };
          getConsultCreate(data).then((res) => {
            _this.$message.success("提交成功！");
            _this.$refs.ruleForm.resetFields();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    solutionBtn(id) {
      this.$router.push(`/solution?id=${id}`)
    },
    toUrl(url) {
      window.open(url, "_blank");
    },
    toHome(url) {
      this.$router.push(url)
    },
    showbtn() {
      let that = this;
      let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (scrollTop > 900) {
        this.showBtn = true;
      } else {
        this.showBtn = false;
      }
    },
    toTop() {
      var timer = setInterval(function () {
        let osTop =
            document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
            osTop + ispeed;
        this.isTop = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    },
  },
};
</script>

<style lang="scss" scoped>
.home-footer {
  background: rgba(0, 0, 0, 0.85);

  .home-footer-box {
    width: 1264px;
    margin: 0 auto;

    .home-footer-lable {
      display: flex;
      border-bottom: 1px solid rgba(255, 255, 255, 0.09);
      justify-content: space-between;
      align-items: center;
      height: 120px;

      > div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #fff;

        > div {
          display: flex;
          align-items: center;

          > img {
            width: 64px;
            height: 64px;
            margin-right: 16px;
          }
        }
      }
    }

    .home-footer-serve {
      display: flex;
      border-bottom: 1px solid rgba(255, 255, 255, 0.09);
      justify-content: space-between;
      align-items: center;
      height: 120px;

      > div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #fff;

        > div {
          display: flex;
          align-items: center;

          > img {
            width: 36px;
            height: 36px;
            margin-right: 12px;
          }
        }
      }
    }

    .home-footer-link {
      display: flex;
      justify-content: space-between;
      padding: 48px 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.09);

      .home-footer-consult {
        > span {
          font-size: 16px;
          line-height: 24px;
          color: #fff;
          margin-bottom: 20px;
          display: block;
        }

        > div {
          > p {
            display: flex;
            align-items: center;
            font-size: 16px;
            color: #fff;
            margin-bottom: 16px;

            > img {
              width: 28px;
              height: 28px;
              margin-right: 14px;
            }
          }

          > div {
            display: flex;
            align-items: center;
            padding-top: 16px;

            > div {
              margin-right: 12px;

              > p {
                display: flex;
                align-items: center;
                font-size: 14px;
                color: rgba(255, 255, 255, 0.65);
                line-height: 20px;
                font-weight: 300;
                margin-bottom: 8px;
                cursor: pointer;

                &:last-child {
                  margin-bottom: 0;
                }

                &:hover {
                  color: #ea0b06;
                }

                > img {
                  margin-right: 4px;
                  width: 16px;
                  height: 16px;
                }
              }
            }

            > img {
              width: 90px;
            }
          }
        }
      }

      .home-footer-link-box {
        display: flex;

        > div {
          margin-right: 90px;

          &:last-child {
            margin-right: 0;
          }

          > span {
            font-size: 16px;
            line-height: 24px;
            color: #fff;
            margin-bottom: 20px;
            display: block;
          }

          > p {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.65);
            line-height: 24px;
            font-weight: 400;
            margin-bottom: 8px;
            cursor: pointer;

            &:last-child {
              margin-bottom: 0;
            }

            &:hover {
              color: #EA0B06;
            }
          }
        }
      }
    }

    .home-footer-copyright {
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.65);
      line-height: 24px;
      font-weight: 400;

      > span {
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .home-footer-frame {
    position: fixed;
    right: 43px;
    bottom: 88px;
    background: #ffffff;
    box-shadow: 0 8px 13px 0 rgba(0, 0, 0, 0.07);
    width: 70px;
    padding: 0 8px;
    z-index: 10;

    > img {
      position: absolute;
      width: 120px;
      height: 120px;
      top: -111px;
      left: 50%;
      margin-left: -60px;
    }

    > div {
      cursor: pointer;
      padding: 12px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.09);

      &:last-child {
        border-bottom: 0;
      }

      > i {
        color: rgba(0, 0, 0, 0.85);
        font-size: 20px;
        display: block;
        margin: 0 auto;
      }

      > span {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.65);
        line-height: 17px;
        display: block;
        margin-top: 6px;
        text-align: center;
      }

      &:hover {
        > i {
          color: #EA0B06;
        }

        > span {
          color: #EA0B06;
        }
      }
    }
  }

  .home-floating {
    position: fixed;
    bottom: 100px;
    right: 16px;
    z-index: 10;

    .home-floating-connect {
      width: 48px;
      background: #ffffff;
      box-shadow: 0px 4px 8px 0px rgba(0, 65, 152, 0.08);
      position: relative;
      margin-bottom: 10px;

      > img {
        position: absolute;
        top: -39px;
        right: 4px;
        z-index: -1;
      }

      > p {
        border-bottom: 1px solid #e8e8e8;
        cursor: pointer;
        padding: 15px;
        text-align: center;
      }
    }

    .home-floating-consult {
      width: 48px;
      height: 130px;
      cursor: pointer;
      position: relative;

      .home-floating-consult-box {
        width: 360px;
        background: #ffffff;
        border: 2px solid #ffffff;
        box-shadow: 0px 4px 8px 0px rgba(0, 65, 152, 0.08);
        position: absolute;
        bottom: 0px;
        right: 56px;
        z-index: 2;

        > img {
          width: 100%;
          display: block;
        }

        > i {
          position: absolute;
          top: 10px;
          right: 10px;
          cursor: pointer;
          font-size: 16px;
        }

        > div {
          padding: 8px 14px;
        }
      }
    }
  }
}

.home-floating-connect-box {
  width: 239px;
  box-shadow: 0 8px 13px 0 rgba(0, 0, 0, 0.07);
  background: #ffffff;
  padding: 24px;
  position: absolute;
  top: -168px;
  right: 92px;

  &::after {
    content: '';
    position: absolute;
    right: -12px;
    bottom: 64px;
    border-top: 6px solid transparent;
    border-left: 6px solid #fff;
    border-bottom: 6px solid transparent;
    border-right: 6px solid transparent;
  }

  > div {
    display: flex;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    > div {
      > span {
        display: block;
        margin-bottom: 4px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
        line-height: 24px;
      }

      > strong {
        display: block;
        line-height: 12px;
        font-size: 12px;
        color: #EA0B06;
        font-weight: 400;
      }

      > p {
        line-height: 12px;
        font-size: 12px;
        color: #EA0B06;
        font-weight: 400;
      }

      > img {
        display: block;
        width: 120px;
        height: 120px;
      }
    }
  }
}

.home-footer-frame-wx {
  display: flex;
  align-items: center;

  > div {
    margin-right: 12px;

    > p {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      line-height: 20px;
      margin-bottom: 8px;
      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        color: #ea0b06;
      }

      > img {
        width: 16px;
        margin-right: 4px;
      }
    }
  }

  > img {
    width: 90px !important;
    height: 90px !important;
  }
}
</style>
<style>
.home-footer-link-anchor {
  padding: 0;
  margin: 0;
  background-color: transparent;
}

.home-footer-link-anchor .ant-anchor-ink {
  display: none;
}

.home-footer-link-anchor > div .ant-anchor-link {
  padding: 0;
}

.home-footer-link-anchor > div .ant-anchor-link > a {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.65);
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 8px;
}

.home-footer-link-anchor > div .ant-anchor-link > a:hover {
  color: #EA0B06;
}

.ant-back-top {
  position: static;
  right: 16px;
  width: 48px;
  height: 48px;
  text-align: center;
  line-height: 48px;
  font-size: 20px;
  background: #fff;
  bottom: 240px;
  box-shadow: 0px 4px 8px 0px rgba(0, 65, 152, 0.08);
}

.home-floating-consult-box .ant-form-item {
  margin-bottom: 14px;
}

.home-floating-consult-box .ant-form-item:last-child {
  margin-bottom: 0;
}

#zxkf-udesk {
  color: #8C8C8C !important;
}
.zxkf-title:hover > span{
  color: #EA0B06 !important;
}

</style>
