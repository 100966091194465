<template>
<div class="program-card">
  <div class="title" @click="toSolution">
    <p>全部解决方案</p>
    <div>
      <img src="@/assets/img/menu/menu1.png" alt="">
    </div>
  </div>
  <div class="content">


    <div class="content-item" v-for="(item,index) in list" :key="index" >


      <div class="content-box" v-for="(i,index) in item" :key="index"  @click="toItem(i.id)">

        <div class="img-box">
          <div>
            <img :src="i.img" alt="">
          </div>
        </div>

        <div class="content-text-box">
            <p class="pRed">{{i.text}}</p>
            <p>{{i.content}}</p>
        </div>
      </div>



    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "program_card",
  props:['sel'],
  data(){
    return{

      // 工业
      industryList:[
        [
          {
            img: require('@/assets/img/scenes/scenes1.png'),
            text:'粉尘监测解决方案',
            content:'打通固定式粉尘检测仪、可燃气体探测器监测检测终端及仪表设备底层系统',
            id:"/solution/info?id=1524556741960794113",
          },
          {
            img: require('@/assets/img/scenes/scenes5.png'),
            text:'精密装配智能制造解决方案',
            content:'利用开源鸿蒙软硬件技术形成PLC、工控机、打印机、扫描枪、测量仪器',
            id:"/solution/info?id=1525661985738444802",
          }
        ],
        [
          {
            img: require('@/assets/img/scenes/scenes2.png'),
            text:'环境监测联网解决方案',
            content:'打通固定式粉尘检测仪、可燃气体探测器监测检测终端及仪表设备底层系统',
            id:"/solution/info?id=1526011444347277314",
          },
          {
            img: require('@/assets/img/scenes/scenes4.png'),
            text:'生物制药智能制造解决方案',
            content:'基于开源鸿蒙的生物制药智能制造系统，利用开源鸿蒙软硬件技术打通烘干、洗瓶和轧盖等生产装备',
            id:"/solution/info?id=1525479134938034178",
          }
        ],
        [
          {
            img: require('@/assets/img/scenes/scenes3.png'),
            text:'智慧农产品供应链解决方案',
            content:'基于开源鸿蒙的智慧农产品供应链系统，以中国农业及农产品的生产、加工、运输和销售分阶段深入分析',
            id:"/solution/info?id=1525433478189940738",
          },
          {
            img: require('@/assets/img/scenes/scenes6.png'),
            text:'数字化质检解决方案',
            content:'基于开源鸿蒙的数字化质检系统利用开源鸿蒙软硬件技术打通生产、数据采集器',
            id:"/solution/info?id=1525777005772017666",
          }
        ]
      ],
      // 消费
      consumeList:[
        [
          {
            img: require('@/assets/img/scenes/scenes7.png'),
            text:'智慧办公室解决方案',
            content:'基于开源鸿蒙智慧办公室系统，利用开源鸿蒙软硬件技术打通办公室的灯光',
            id:"/solution/info?id=1519367306784821250",
          },
          {
            img: require('@/assets/img/scenes/scenes9.png'),
            text:'智慧景区解决方案',
            content:'“智慧旅游”是大众旅游时代下的必然产物，是一种以物联网、云计算',
            id:"/solution/info?id=1526039501946777602",
          }
        ],
        [
          {
            img: require('@/assets/img/scenes/scenes8.png'),
            text:'智能广告机解决方案',
            content:'基于开源鸿蒙的智能广告机管理系统利用开源鸿蒙软硬件技术打通液晶拼接屏',
            id:"/solution/info?id=1524671366643630081",
          },
          {
            img: require('@/assets/img/scenes/scenes11.png'),
            text:'可穿戴装备解决方案',
            content:'基于开源鸿蒙的可穿戴装备系统利用开源鸿蒙软硬件技术，采用血氧、心率、环境等传感器形成智能手环',
            id:"/solution/info?id=1526082597183057921",
          }
        ],
        [
          {
            img: require('@/assets/img/scenes/scenes12.png'),
            text:'数字影院解决方案',
            content:'基于开源鸿蒙的数字影院系统利用开源鸿蒙软硬件技术打通智能售票机',
            id:"/solution/info?id=1526039501946777602",
          },
          {
            img: require('@/assets/img/scenes/scenes10.png'),
            text:'无线家居安防解决方案',
            content:'基于开源鸿蒙的无线家居安防系统利用开源鸿蒙软硬件技术打通摄像头',
            id:"/solution/info?id=1526125329238724610",
          }
        ]
      ],
      //政府
      governmentList:[
        [
          {
            img: require('@/assets/img/scenes/scenes14.png'),
            text:'重点车辆管理解决方案',
            content:'基于开源鸿蒙的重点车辆管理系统，通过开源鸿蒙软硬件技术将车辆GPS、RFID',
            id:"/solution/info?id=1519334989714444290",
          },
          {
            img: require('@/assets/img/scenes/scenes13.png'),
            text:'智慧社区解决方案',
            content:'利用开源鸿蒙软硬件技术及分布式框架整合各种传感器资源，统一建设智慧照明',
            id:"/solution/info?id=1517061968324775937",
          }
        ],
        [
          {
            img: require('@/assets/img/scenes/scenes15.png'),
            text:'智慧防疫解决方案',
            content:'基于开源鸿蒙的智慧防疫系统利用开源鸿蒙软硬件技术形成核酸采集（双路核酸采集工作站）',
            id:"/solution/info?id=1523906936951623682",
          },
          {
            img: require('@/assets/img/scenes/scenes16.png'),
            text:'校园安全解决方案',
            content:'基于开源鸿蒙的校园安全系统，通过开源鸿蒙软硬件技术和广域物联网连接技术，在智慧校园的场景下，通…',
            id:"/solution/info?id=1519330206215589889",
          }
        ],
        [
          {
            img: require('@/assets/img/scenes/scenes18.png'),
            text:'便利店解决方案',
            content:'基于国产自主开源鸿蒙软硬件技术搭建的“基于开源鸿蒙的新便利店系统”',
            id:"/solution/info?id=1517021491319808002",
          },
          {
            img: require('@/assets/img/scenes/scenes17.png'),
            text:'视频硬件技术的柔性执法解决方案',
            content:'以开源鸿蒙系统及技术为基础，利用国产视频硬件新增或替换现有摄像头、无人机和巡查车的视频采集装置',
            id:"/solution/info?id=1516976738997895169",
          }
        ]
      ],
    }
  },
  computed:{
    list(){
      let map={'1500000000000000003':this.industryList,'1500000000000000001':this.consumeList,'1500000000000000002':this.governmentList}
      return map[this.sel]
    }
  },
  methods:{
    toItem(url){
      this.$emit('change',false)
      this.$router.push(url)
    },
    toSolution(){
      this.$emit('change',false)
      this.$router.push(`/solution/?i=${this.sel}`)
    }
  },
}
</script>

<style scoped lang="scss">
.program-card{
  .title{
    display: flex;
    margin-bottom: 24px;
    &:hover{
      cursor: pointer;
    }
    >p{
      font-size: 14px;
      font-weight: bold;
      line-height: 28px;
      color: #EA0B06;
      transition:all 0.3s ease-in-out;
    }
    >p:hover{
      margin-right: 8px;
    }
    >div{
      width: 30px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;

    }
  }
  >.content{
    display: flex;
    justify-content: flex-start;
    >.content-item{
      width: 328px;
    }
    >:not(:last-child){
      margin-right: 56px;
    }
    .content-box{
      display: flex;
      justify-content: flex-start;
      margin-bottom: 40px;
      &:hover{
        cursor: pointer;
        .pRed{
          color: #EA0B06;
        }
      }

      >.img-box{
        width: 24px;
        >div{
          width: 24px;
          height: 24px;
          >img{
            width: 100%;
            height: 100%;
          }
        }
      }
      >.content-text-box{
        padding:2px 0 0 12px;
         :first-child{
           font-size: 14px;
           font-weight: 500;
           //color: #262626;
           line-height: 20px;
           margin-bottom: 8px;

         }
          :last-child{
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            color: #666666;
          }
      }
    }
  }
}
</style>
