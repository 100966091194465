<template>
<div class="menu-card">
  <div class="menu-left">

    <div class="text-box">
      <p v-for="item in val" :key="item.id"  @mouseenter="select(item.id)" :class="{'selected':item.id===id}">{{item.title}}</p>
    </div>

  </div>

  <div class="menu-right">
    <div class="close" @click="$emit('change',false)">
      <img src='../../../assets/img/menu2.png' alt="">
    </div>
    <div class="menu-input">
      <a-input
          placeholder="请输入关键词"
          size="large"
          @pressEnter="onSearch"
          v-model="searchVal"
      >
            <span slot="suffix" @click="onSearch" style="cursor: pointer;">
                <img alt="图片" src="../../../assets/img/menu3.png"/>
            </span>
      </a-input>
      <div class="item-box">
        <span @click="popularSearchBtn('医疗')">医疗</span>
        <span @click="popularSearchBtn('金融')">金融</span>
        <span @click="popularSearchBtn('教育')">教育</span>
      </div>
    </div>
    <div class="menu-content">
      <slot name="product" :id="id"></slot>
      <slot name="solution" :id="id"></slot>
    </div>

  </div>
</div>
</template>

<script>
export default {
  name: "menu_card",
  props:['val','show'],
  data(){
    return{
      searchVal: "",
      id:"1500000000000000003",
    }
  },
 watch:{
   show(data){
     if(!data) this.id='1500000000000000003'
   }
 },
  methods:{
    onSearch() {
        let {searchVal} = this;
        if (!searchVal) {
            this.$message.warning("请输入搜索关键词！");
            return;
        }
        this.$emit('searchange',false)
        this.$router.push(`/searchInfo?val=${searchVal}`);
        this.searchVal = "";
    },
    popularSearchBtn(val) {
        this.$emit('btnchange',false)
        this.$router.push(`/searchInfo?val=${val}`);
    },
    select(id){
      this.id=id
    },
  }
}
</script>

<style scoped lang="scss">
.menu-card{
  width: 100%;
  height: 480px;
  display: flex;
  justify-items: flex-start;
  border-top: 1px solid #E2E8F0;
  box-shadow: 0 5px 5px rgba(0,65,152,0.12);
}

.menu-left{
  width: 476px;
  height: 480px;
  background: url("../../../assets/img/menu1.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  >.text-box{
    position: absolute ;
    top: 40px;
    left: 240px;
    >p{
      position: relative;
      font-size: 14px;
      line-height: 41px;
      font-weight: 400;
      color: #262626;
      height: 41px;
      box-sizing: border-box;
      margin-bottom: 8px;
      &::after{
        content:'';
        display:block;
        width:0;
        height:2px;
        position:absolute;
        left:0;
        bottom:0;
        background:#EA0B06;
        transition:all 0.3s ease-in-out;
      }
    }
    .selected{
      color: #EA0B06;
      cursor: pointer;
      &::after{
        width:100%;
      }
    }





  }
}

.menu-right{
  width: calc(100% - 476px);
  //flex-grow: 1;
  height: 480px;
  position: relative;
  background: #fff;
  >.close{
    width: 18px;
    height: 18px;
    position: absolute;
    top: 33px;
    right: 32px;
    >img{
      width: 100%;
      height: 100%;
    }
    &:hover{
      cursor: pointer;
    }
  }

  >.menu-input{

    height: 36px;
    margin-top: 24px;
    padding-left:40px;
    display: flex;
    justify-items: flex-start;
    &::v-deep{
      .ant-input-affix-wrapper{
        width: 320px;
        height: 36px;
        background: #F1F2F5;
      }
      .ant-input{
        width: 320px;
        height: 36px;
        background: #F1F2F5;
        border: none;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .item-box{
      margin-left: 24px;
       >span{
         display: inline-block;
         background: #F1F2F5;
         width: 52px;
         height: 36px;
         font-size: 14px;
         line-height: 36px;
         text-align: center;
         color: #262626;
         margin-right: 12px;
         &:hover{
           cursor: pointer;
           color: #EA0B06;
         }
       }

    }
  }


  >.menu-content{
    margin: 40px 0 0 40px;
  }
}





</style>
