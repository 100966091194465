<template>
  <div class="platform-apply">
    <div @click="toItem('/platform')">
        <div>混合云</div>
        <p>面向异构混合云环境、传统数据中心的资源管理和业务系统支撑平台。实现云上、云下资源的统一管理、监控、运维、运营，提升IT服务水平，增加资源利用率，降低IT成本</p>
    </div>
    <div @click="toItem('/platform/yuansheng')">
        <div>云原生</div>
        <p>提供了容器云管理平台及下一代微服务架构-服务网格的解决方案。借助新一代服务网格解决方案，可保持业务现有技术栈，提供无感的接入体验，让业务快速接入云原生</p>
    </div>
    <div @click="toItem('/platform/serve')">
        <div>微服务</div>
        <p>针对微服务架构差异性大、服务庞杂、开发困难等问题，提供自研微服务架构，帮助车联网企业搭建适于其业务和现状的微服务应用，构建敏捷生态组织</p>
    </div>
    <div @click="toItem('/platform/code')">
        <div>低代码</div>
        <p>向上连接车联网行业业务需求，通过“拖拽式”开发赋能车联网数字化生产，向下连接海量云计算能力释放云的力量</p>
    </div>
  </div>
</template>

<script>
export default {
  methods:{
    toItem(url){
      this.$emit('change',false)
      this.$router.push(url)
    }
  }
}
</script>

<style lang="scss" scoped>
.platform-apply{
    display: flex;
    >div{
        width: 240px;
        margin-right: 40px;
        cursor: pointer;
        >div{
            height: 72px;
            line-height: 72px;
            padding: 0 16px;
            font-size: 16px;
            color: #262626;
            margin-bottom: 12px;
        }
        >p{
            font-size: 12px;
            line-height: 18px;
            color: #666666;
        }
        &:hover{
            >div{
                color: #EA0B06;
            }
        }
        &:nth-child(1){
            >div{
                background: url(../../../assets/img/hhy.png) no-repeat;
                background-size: 100% 100%;
            }
        }
        &:nth-child(2){
            >div{
                background: url(../../../assets/img/yys.png) no-repeat;
                background-size: 100% 100%;
            }
        }
        &:nth-child(3){
            >div{
                background: url(../../../assets/img/wfw.png) no-repeat;
                background-size: 100% 100%;
            }
        }
        &:last-child{
            margin-right: 0;
            >div{
                background: url(../../../assets/img/ddm.png) no-repeat;
                background-size: 100% 100%;
            }
        }
    }
}
</style>
